@import './components/create-product.scss';
@import './components/page-header.scss';
@import './components/filter-bar.scss';
@import './utilities/config';
@import './components/page-header.scss';
@import './components/order-detail.scss';
@import './pages/login';
.App {
  color: red !important;
}
.layout {
  min-height: 100vh !important;
}
.sider {
  background-color: white !important;
}
.header {
  @include rowFlex(flex-end, center, nowrap);
  & .ant-space-item {
    color: white;
  }
}
.content-editor {
  background-color: white;
  min-height: calc(100vh - 300px);
  padding: 0 20px;
}
.content-wrapper {
  margin-top: 24px;
}
