//create product

.container {
  width: 100%;
}

.site-page-header {
  background-color: #fff !important;
}

.product-box {
  display: flex;
  margin: 15px 0px !important;
  width: 100%;
  .upload {
    background-color: #fff;
  }
  .upload-text,
  .upload-image {
    padding: 40px 24px;
    background-color: #fff !important;
    .upload-text__description {
      min-height: 170px;
      .quill {
        height: 100px;
      }
    }
  }

  .upload-image {
    flex-grow: 1;
    padding-right: 0;
    min-height: 360px;
    height: 100%;
  }

  .submit-box {
    width: 100%;
  }
}

.image-item {
  max-width: 100%;
  img {
    max-width: 100%;
    max-height: 200px;
  }
}

.table-btn {
  color: #1890ff;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .product-box {
    flex-direction: column;
    .upload-text,
    .upload-image {
      margin-left: 0 !important;
      margin-top: 20px !important;
    }

    .upload-image {
      margin-left: 0px !important;
    }
  }
}

.product-info-container {
  padding: 12px 24px;
  background-color: #fff;
  margin-bottom: 20px;
}
