@mixin rowFlex($justify, $align, $wrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}
@mixin columnFlex($justify, $align, $wrap) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}
