.login {
  min-height: 100vh;
  @include columnFlex(center, center, nowrap);
  &__body {
    width: 80%;
    max-width: 300px;
    @include columnFlex(flex-start, center, nowrap);
    & form {
      width: 100%;
    }
    & .center-button {
      & .ant-form-item-control-input-content {
        @include rowFlex(center, center, nowrap);
      }
    }
  }
  &__error {
    text-align: center;
    color: #ff4d4f;
  }
}
